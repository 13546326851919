<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="40%"
  >
    <div class="handle-content">
      <el-form
        ref="dataForm"
        :model="dataForm"
        :rules="dataRule"
        class="demo-form-inline"
      >
        <el-form-item label="IP1" :label-width="'90px'" prop="ip0">
          <el-input v-model="dataForm.ip0" placeholder="请输入IP地址" />
        </el-form-item>
        <el-form-item label="端口1" :label-width="'90px'" prop="port0">
          <el-input v-model="dataForm.port0" placeholder="请输入端口" />
        </el-form-item>
        <el-form-item label="IP2" :label-width="'90px'" prop="ip1">
          <el-input v-model="dataForm.ip1" placeholder="请输入IP地址" />
        </el-form-item>
        <el-form-item label="端口2" :label-width="'90px'" prop="port1">
          <el-input v-model="dataForm.port1" placeholder="请输入端口" />
        </el-form-item>
      </el-form>
    </div>

    <template slot="footer">
      <el-button type="primary" :loading="loading" @click="dataFormSubmitHandle()">确认</el-button>
      <el-button @click="visible = false">取消</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from "lodash/debounce";
import "./SetupModel.less";
import axios from "@/utils/axios";
export default {
  data() {
    return {
      /**是否显示弹窗 */
      visible: false,
      loading: false,
      title: null,
      dataForm: {
        command: null,
        sn: null,
        ip0: null,
        ip1: null,
        port0: null,
        port1: null,
        organId: null
      },
    };
  },
  computed: {
    dataRule() {
      return {
        ip0: [{ required: true, message: "IP地址不能为空", trigger: "bulr" }],
        port0: [{ required: true, message: "端口不能为空", trigger: "bulr" }],
      };
    },
  },
  methods: {
    init() {
      this.visible = true;
      this.dataForm.organId = localStorage.getItem("organId")
      this.$nextTick(() => {
        // this.$refs["dataForm"].resetFields();
      });
    },

    // 表单提交
    dataFormSubmitHandle: debounce(
      function () {
        this.$refs["dataForm"].validate((valid) => {
          if (!valid) {
            return false;
          }
          this.loading = true
          axios
            .post(`/api/command/send`, this.dataForm)
            .then((res) => {
              this.loading = false
              return this.$message.success(res);
            })
            .catch((err) => {
              this.loading = false
              return this.$message.error(err);
            });
        });
      },
      1000,
      { leading: true, trailing: false }
    ),
  },
};
</script>
