<template>
  <div class="detail-container">
    <div class="device-detail-title">
      <i class="el-icon-arrow-left" @click="backToList"></i>
      <span>设备控制</span>
    </div>
    <div class="msg-list-tools">
      <el-form :inline="true">
        <el-form-item label="设备ID:">
          <!-- <el-input
            v-model="search.sn"
            style="width: 220px"
            placeholder="输入设备ID"
          ></el-input> -->
          <el-select v-model="search.sn" filterable :style="{ width: '315px' }">
            <el-option
              v-for="item in deviceList"
              :key="item.id"
              :label="item.sn"
              :value="item.sn"
              
            ></el-option>
          </el-select>
        </el-form-item>
        <div style="float: right">
          <el-form-item>
            <el-button type="primary" size="small" @click="getData"
              >查询</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="data-base-info">
      <div class="data-base-title">资产基础信息</div>
      <el-row class="data-row">
        <el-col :span="3">
          <div class="data-sn">设备编号</div>
        </el-col>
        <el-col :span="3">
          <div class="data-content">{{ pageData.sn || "-" }}</div>
        </el-col>
        <el-col :span="3">
          <div class="data-sn">产品类别</div>
        </el-col>
        <el-col :span="3">
          <div class="data-content">{{ pageData.category || "-" }}</div>
        </el-col>
        <el-col :span="3">
          <div class="data-sn">产品类型</div>
        </el-col>
        <el-col :span="3">
          <div class="data-content">{{ pageData.type || "-" }}</div>
        </el-col>
        <el-col :span="3">
          <div class="data-sn">厂家</div>
        </el-col>
        <el-col :span="3">
          <div class="data-content">{{ pageData.factory || "-" }}</div>
        </el-col>
      </el-row>
      <el-row class="data-sub-row">
        <el-col :span="3">
          <div class="data-sn">电芯</div>
        </el-col>
        <el-col :span="3">
          <div class="data-content">{{ pageData.cellFactory || "-" }}</div>
        </el-col>
        <el-col :span="3">
          <div class="data-sn">电芯类型</div>
        </el-col>
        <el-col :span="3">
          <div class="data-content">{{ pageData.cellType || "-" }}</div>
        </el-col>
        <el-col :span="3">
          <div class="data-sn">电压平台</div>
        </el-col>
        <el-col :span="3">
          <div class="data-content">{{ pageData.voltPlatform || "-" }}</div>
        </el-col>
        <el-col :span="3">
          <div class="data-sn">容量</div>
        </el-col>
        <el-col :span="3">
          <div class="data-content">{{ pageData.capacity || "-" }}</div>
        </el-col>
      </el-row>
      <el-row class="data-sub-row">
        <el-col :span="3">
          <div class="data-sn">生产时间</div>
        </el-col>
        <el-col :span="3">
          <div class="data-content">{{ pageData.productTime || "-" }}</div>
        </el-col>
        <el-col :span="3">
          <div class="data-sn">投营时间</div>
        </el-col>
        <el-col :span="3">
          <div class="data-content">{{ pageData.operationTime || "-" }}</div>
        </el-col>
        <el-col :span="3">
          <div class="data-sn">采购价格</div>
        </el-col>
        <el-col :span="3">
          <div class="data-content">{{ pageData.price || 0 }}</div>
        </el-col>
      </el-row>
    </div>
    <div class="lock-info">
      <div class="data-base-title">锁定/解锁设置</div>
      <div class="lock-set">
        <div class="curr-status">当前状态：{{curData.status === '0'?'解锁':'锁定'}}</div>
        <el-button
          style="margin-left: 40"
          type="primary"
          :disabled="curData.status === '1'"
          @click="setBattery(2, 1)"
          :loading="loading"
          >锁定</el-button
        >
        <el-button
          style="margin-left: 40px"
          type="primary"
          :disabled="curData.status === '0'"
          @click="setBattery(2, 0)"
          :loading="loading"
          >解锁</el-button
        >
        <!-- <el-switch
          v-model="value"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-value="1"
          inactive-value="0"
          active-text="锁定"
          inactive-text="解锁"
          @change="lockBattery"
        >
        </el-switch> -->
      </div>
    </div>
    <div class="lease-info">
      <div class="data-base-title">租期设置</div>
      <div class="curr-lease">当前租期： {{curData.rentStart?(curData.rentStart +' 至 '+ curData.rentEnd):'暂无租期'}}</div>
      <div class="lease-time">
        <div class="time-title">开始时间：</div>
        <el-date-picker
          v-model="search.startTime"
          type="datetime"
          style="width: 220px; margin-left: 10px"
          placeholder="选择日期"
          @change="timeChange">
        </el-date-picker>
      </div>
      <div class="lease-time">
        <div class="time-title">天数：</div>
        <el-input
          placeholder="天数"
          style="width: 220px; margin-left: 40px"
          v-model="search.days"
        ></el-input>
        <el-button
          style="margin-left: 100px"
          type="primary"
          :loading="loading"
          @click="setBattery(1, 10)"
          >设置</el-button
        >
      </div>
    </div>
    <div class="set-button">
      <div class="data-base-title">其他设置</div>
      <div class="button-items">
        <el-button
          style="margin-left: 0"
          type="primary"
          :loading="loading"
          @click="setBattery(3, 10)"
          >响铃</el-button
        >
        <el-button
          style="margin-left: 40px"
          type="primary"
          :loading="loading"
          @click="setBattery(4, 10)"
          >加热</el-button
        >
        <el-button
          style="margin-left: 40px"
          type="primary"
          :loading="loading"
          @click="setBattery(5, 10)"
          >授时</el-button
        >
        <el-button
          style="margin-left: 40px"
          type="primary"
          :loading="loading"
          @click="setBattery(6, 10)"
          >断开</el-button
        >
        <el-button
          style="margin-left: 40px"
          type="primary"
          @click="changeBattery()"
          >切换</el-button
        >
      </div>
    </div>
    <SetupModel v-if="visible" ref="setModel"/>
  </div>
</template>

<script>
import axios from "@/utils/axios";
import moment from "moment";
import "./SetupDetail.less";
import SetupModel from "./components/SetupModel";
export default {
  name: "SetupDetail",
  data() {
    return {
      pageData: {},
      visible: false,
      loading: false,
      search: {
        sn: null,
        command: null,
        days: null,
        lock: null,
        startTime: null,
        organId: null
      },
      value: 1,
      pageData: {},
      organId: null,
      deviceList: [],
      curData: {}
    };
  },
  components: {
    SetupModel,
  },
  mounted() {
    this.organId = localStorage.getItem("organId")
    this.search.organId = localStorage.getItem("organId")
    this.getDeviceList()
  },
  methods: {
    //返回上一页
    backToList() {
      this.$store.dispatch("tagsView/delView", this.$route);
      this.$router.go(-1);
    },
    //获取电池详情
    getData() {
      let param = {
        sn: this.search.sn,
      };
      axios
        .post(`/api/device/detail`, param)
        .then((rs) => {
          this.pageData = rs;
          this.activities = rs.timeLine;
        })
        .catch((err) => {
          this.$message.error(err);
        });
        this.getCurStatus()
    },
    //获取当前设备的当前状态和租期
    getCurStatus() {
      let param = {
        sn: this.search.sn,
      };
      this.loading = true
      axios
        .post(`/api/command/getCurStatusAndRent`, param)
        .then((rs) => {
          this.curData = rs;
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          this.$message.error(err);
        });
    },
    //获取当前机构下所有设备
    getDeviceList() {
       axios
        .post("/api/device/getDevices", { id: this.organId })
        .then((rs) => {
          this.deviceList = rs;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    //时间选择
    timeChange(data) {
      if (data) {
        this.search.startTime = this.DateToStr(data);
      }
    },
    //日期转字符串
    DateToStr(date) {
      var year = date.getFullYear(); //年
      var month = date.getMonth(); //月
      var day = date.getDate(); //日
      var hours = date.getHours(); //时
      var min = date.getMinutes(); //分
      var second = date.getSeconds(); //秒
      return (
        year +
        "-" +
        (month + 1 > 9 ? month + 1 : "0" + (month + 1)) +
        "-" +
        (day > 9 ? day : "0" + day) 
        +
        " " +
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (min > 9 ? min : "0" + min) +
        ":" +
        (second > 9 ? second : "0" + second)
      );
    },
    //电池操作
    setBattery(type, value) {
      let { search } = this;
      if (!search.sn) {
        return this.$message.error("请输入设备编号");
      }
      if (type === 2) {
        search.lock = value;
        search.command = type;
      } else {
        search.command = type;
      }
      this.batterySend(search);
    },
    changeBattery() {
      this.visible = true
      this.$nextTick(() => {
        this.$refs.setModel.init()
        this.$refs.setModel.title = this.search.sn + " 切换";
        this.$refs.setModel.dataForm.sn = this.search.sn;
        this.$refs.setModel.dataForm.command = 7;
      })  
    },
    //下发命令a
    batterySend(search) {
      let text = "";
      if (search.command === 1) {
        text = "设置租期";
      } else if (search.command === 2) {
        if (search.lock === 0) {
          text = "解锁";
        } else if (search.lock === 1) {
          text = "锁定";
        }
      } else if (search.command === 3) {
        text = "响铃";
      } else if (search.command === 4) {
        text = "加热";
      } else if (search.command === 5) {
        text = "授时";
      } else if (search.command === 6) {
        text = "断开";
      }
      this.$confirm(`确认${text}操作吗, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true
        axios
          .post(`/api/command/send`, search)
          .then((rs) => {
            this.loading = false
            if(search.command === 2 || search.command === 1) {
              this.getCurStatus()
            }
            return this.$message.success(rs);
          })
          .catch((err) => {
            this.loading = false
            this.$message.error(err);
          });
      });
    },
  },
};
</script>